.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.counter-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.counter {
  text-align: center;
  padding: 20px;
  border: 2px solid lightblue; /* Light blue border */
  border-radius: 10px;
  background-color: white; /* White background */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow for depth */
}

.counter-label {
  font-size: 18px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  color: #333; /* Dark text color */
}

.counter-value {
  font-size: 24px;
  font-weight: bold;
  color: #333; /* Dark text color */
}

.zoom-animation {
  animation: zoomIn 0.2s ease forwards;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(2);
  }
}

.services-container {
  /* Set display to flex */
  display: flex;
}

/* Ensure flex items wrap properly */
.services-container.flex-wrap {
  flex-wrap: wrap;
}

/* Center the flex items horizontally */
.services-container.justify-content-center {
  justify-content: center;
}